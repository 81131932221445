import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    searchCustomer(ctx, customer) {
      return new Promise((resolve, reject) => {
        axios
          .get('https://posapi.owlstime.com/api/Member/SearchMember', {
            params: {
              Name: customer.name,
              Surname: customer.surname,
              NickName: customer.nickName,
              Phone: customer.phone,
              Email: customer.email,
              Gender: customer.gender,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getCustomer(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get('https://posapi.owlstime.com/api/Member/getbyid', {
            params: {
              Id: id,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCustomer(ctx, customer) {
      return new Promise((resolve, reject) => {
        axios
          .put('https://posapi.owlstime.com/api/Member/insert', customer)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editCustomer(ctx, customer) {
      return new Promise((resolve, reject) => {
        axios
          .post('https://posapi.owlstime.com/api/Member/update', customer)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllergy(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get('https://posapi.owlstime.com/api/FnbCrmChar/GetCrmAllergyByBranch', {
            params: {
              branch_id: id,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getApperance(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get('https://posapi.owlstime.com/api/FnbCrmChar/GetCrmAppearanceByBranch', {
            params: {
              branch_id: id,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getBrand(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get('https://posapi.owlstime.com/api/FnbCrmChar/GetCrmBrandByBranch', {
            params: {
              branch_id: id,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDrink(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get('https://posapi.owlstime.com/api/FnbCrmChar/GetCrmDrinkByBranch', {
            params: {
              branch_id: id,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}

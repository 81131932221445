<template>
  <div>
    <b-row>
      <b-col>
        <b-card class="mb-1">
          <b-row>
            <b-col>
              <b-form-group
                label-cols="4"
                label="Name"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="searchModel.name"
                  placeholder="Search Information ..."
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label-cols="4"
                label="Surname"
                label-for="surname"
              >
                <b-form-input
                  id="surname"
                  v-model="searchModel.surname"
                  placeholder="Search Information ..."
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label-cols="4"
                label="Nick Name"
                label-for="nickName"
              >
                <b-form-input
                  id="nickName"
                  v-model="searchModel.nickName"
                  placeholder="Search Information ..."
                />
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                @click="clear()"
              >
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span class="align-middle">Clear</span>
              </b-button>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group
                label-cols="4"
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="searchModel.email"
                  placeholder="Search Information ..."
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label-cols="4"
                label="Gender"
                label-for="gender"
              >
                <v-select
                  id="gender"
                  v-model="searchModel.gender"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="genderOption"
                  placeholder="Please Select"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label-cols="4"
                label="Phone"
                label-for="phone"
              >
                <b-form-input
                  id="phone"
                  v-model="searchModel.phone"
                  placeholder="Search Information ..."
                />
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                @click="searchCustomer()"
              >
                <feather-icon
                  icon="SearchIcon"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card class="mb-1">
          <b-card-body>
            <b-row>
              <b-col>
                <b-card title="Personal Information">
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Name"
                        label-for="name"
                      >
                        <b-form-input
                          id="name"
                          v-model="customerModel.name"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col>
                      <b-form-group
                        label="Surname"
                        label-for="surname"
                      >
                        <b-form-input
                          id="surname"
                          v-model="customerModel.surname"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Nick Name"
                        label-for="nickName"
                      >
                        <b-form-input
                          id="nickName"
                          v-model="customerModel.nickName"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Gender"
                        label-for="gender"
                      >
                        <v-select
                          id="gender"
                          v-model="customerModel.gender"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="genderOption"
                          placeholder="Please Select"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Salution"
                        label-for="salution"
                      >
                        <b-form-input
                          id="salution"
                          v-model="customerModel.salution"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col>
                      <b-form-group
                        label="Phone No."
                        label-for="phone"
                      >
                        <b-form-input
                          id="phone"
                          v-model="customerModel.phone"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Email"
                        label-for="email"
                      >
                        <b-form-input
                          id="email"
                          v-model="customerModel.email"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col>
                      <b-form-group
                        label="Birthdate"
                        label-for="birthday"
                      >
                        <b-form-datepicker
                          id="birthday"
                          v-model="customerModel.birthday"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          locale="en"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Special Day"
                        label-for="specialDay"
                      >
                        <b-form-input
                          id="specialDay"
                          v-model="customerModel.specialDay"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col>
                      <b-form-group
                        label="Special Date"
                        label-for="specialDate"
                      >
                        <b-form-datepicker
                          id="specialDate"
                          v-model="customerModel.specialDate"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          locale="en"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Place Of Residence"
                        label-for="placeOfResidence"
                      >
                        <b-form-input
                          id="placeOfResidence"
                          v-model="customerModel.placeOfResidence"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col>
                      <b-form-group
                        label="Occupation"
                        label-for="occupation"
                      >
                        <b-form-input
                          id="occupation"
                          v-model="customerModel.occupation"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>

              <b-col>
                <b-card title="Charactor Information">
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Appearance"
                        label-for="appearance"
                      >
                        <v-select
                          id="appearance"
                          v-model="customerModel.apperanceSelect"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          multiple
                          taggable
                          push-tags
                          :options="apperanceOption"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Favourite Drinks"
                        label-for="favDrink"
                      >
                        <v-select
                          id="favDrink"
                          v-model="customerModel.favDrinkSelect"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          multiple
                          taggable
                          push-tags
                          :options="favDrinkOption"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col>
                      <b-form-group
                        label="Preferred Drinks"
                        label-for="preferDrink"
                      >
                        <v-select
                          id="preferDrink"
                          v-model="customerModel.preferDrinkSelect"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          multiple
                          taggable
                          push-tags
                          :options="favDrinkOption"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Favourite Brand"
                        label-for="favBrand"
                      >
                        <v-select
                          id="favBrand"
                          v-model="customerModel.favBrandSelect"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          multiple
                          taggable
                          push-tags
                          :options="favBrandOption"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col>
                      <b-form-group
                        label="Preferred Brand"
                        label-for="preferBrand"
                      >
                        <v-select
                          id="preferBrand"
                          v-model="customerModel.preferBrandSelect"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          multiple
                          taggable
                          push-tags
                          :options="favBrandOption"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Allergy"
                        label-for="allergy"
                      >
                        <v-select
                          id="allergy"
                          v-model="customerModel.allergySelect"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          multiple
                          taggable
                          push-tags
                          :options="allergyOption"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col>
                      <b-form-group
                        label="Concern Detail"
                        label-for="concern"
                      >
                        <b-form-input
                          id="concern"
                          v-model="customerModel.concern"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="4">
                      <label>Preferred Contact Method</label>
                    </b-col>

                    <b-col cols="3">
                      <b-form-checkbox
                        id="isPhone"
                        v-model="customerModel.isPhone"
                      >
                        Phone Call
                      </b-form-checkbox>
                    </b-col>

                    <b-col>
                      <b-form-checkbox
                        id="isSMS"
                        v-model="customerModel.isSMS"
                      >
                        SMS
                      </b-form-checkbox>
                    </b-col>

                    <b-col>
                      <b-form-checkbox
                        id="isEmail"
                        v-model="customerModel.isEmail"
                      >
                        Email
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-footer class="text-right">
            <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
              />
              <span class="align-middle">Edit</span>
            </b-button> -->
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              :disabled="disabledButton"
              @click="saveCustomer()"
            >
              <b-spinner
                v-if="isShowLoading"
                small
                class="mr-1"
              />
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save</span>
            </b-button>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-header>
            <b-card-title>Purchase History</b-card-title>
            <div class="d-flex align-items-center">
              <feather-icon
                icon="CalendarIcon"
                size="16"
              />
              <flat-pickr
                v-model="rangePicker"
                :config="{ mode: 'range'}"
                class="form-control flat-picker bg-transparent border-0 shadow-none"
                placeholder="YYYY-MM-DD"
              />
            </div>
          </b-card-header>

          <b-card-body>
            <b-row class="match-height">
              <b-col md="7">
                <chartjs-component-bar-chart
                  :height="200"
                  :data="barChart.data"
                  :options="barChart.options"
                />
              </b-col>
              <b-col>
                <!-- <chartjs-component-doughnut-chart
                  :height="150"
                  :data="doughnutChart.data"
                  :options="doughnutChart.options"
                  class="mb-1"
                /> -->
                <vue-apex-charts
                  type="pie"
                  height="160"
                  class="mt-1 mb-1"
                  :options="pieChart.chartOptions"
                  :series="pieChart.series"
                />

                <div class="d-flex align-items-center pl-3">
                  <div
                    v-for="(pieItem) in pieData"
                    :key="pieItem.text"
                  >
                    <feather-icon
                      icon="CircleIcon"
                      size="16"
                      :class="pieItem.color"
                    />
                    <span class="font-weight-bold ml-75 mr-25">{{ pieItem.text }}</span>
                    <span class="mr-1">- {{ pieItem.result }}</span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <!-- modal backdrop -->
    <b-modal
      id="modal-no-backdrop"
      ref="my-modal"
      centered
      size="xl"
      hide-backdrop
      hide-header-close
      no-close-on-backdrop
      content-class="shadow"
      ok-title="Accept"
      cancel-variant="outline-secondary"
      @ok="handleOk"
      @hidden="resetModal"
    >
      <b-row class="d-flex align-items-center justify-content-end">
        <b-col
          cols="5"
          class="my-1"
        >
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-table
            responsive="sm"
            selectable
            :select-mode="selectMode"
            :per-page="perPage"
            :current-page="currentPage"
            :items="customers"
            :fields="customerColumns"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            @row-selected="onRowSelected"
          >
            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected">
                <i class="feather icon-disc primary" />
              </template>

              <template v-else>
                <i class="feather icon-circle" />
              </template>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="3">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="9"
          class="d-flex align-items-center justify-content-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>

import {
  BRow, BCol, BCard, BCardTitle, BCardHeader, BCardBody, BCardFooter,
  BFormCheckbox, BButton, BFormInput, BFormGroup, BSpinner,
  BFormDatepicker, BModal, VBModal, BTable, BPagination, BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { $themeColors } from '@themeConfig'
import ChartjsComponentBarChart from './ChartjsComponentBarChart.vue'
// import ChartjsComponentDoughnutChart from './ChartjsComponentDoughnutChart.vue'
import VueApexCharts from 'vue-apexcharts'
import customerStoreModule from './customerStoreModule'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BFormCheckbox,
    BModal,
    BButton,
    BFormInput,
    BFormGroup,
    BFormDatepicker,
    BTable,
    BPagination,
    BFormSelect,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
    ChartjsComponentBarChart,
    // ChartjsComponentDoughnutChart,
    flatPickr,
    VueApexCharts,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      isVerticalMenuCollapsed: this.$store.state.verticalMenu.isVerticalMenuCollapsed,
      rangePicker: ['2022-07-01', '2022-07-31'],
      searchModel: {
        name: '',
        surname: '',
        nickName: '',
        email: '',
        gender: '',
        phone: '',
      },
      customerModel: {
        id: 0,
        name: '',
        surname: '',
        nickName: '',
        gender: '',
        salution: '',
        email: '',
        phone: '',
        birthday: '',
        specialDay: '',
        specialDate: '',
        placeOfResidence: '',
        occupation: '',
        apperance: '',
        apperanceSelect: [],
        favDrink: '',
        favDrinkSelect: [],
        preferDrink: '',
        preferDrinkSelect: [],
        favBrand: '',
        favBrandSelect: [],
        preferBrand: '',
        preferBrandSelect: [],
        allergy: '',
        allergySelect: [],
        concern: '',
        isPhone: false,
        isSMS: false,
        isEmail: false,
      },
      customers: [],
      // select row table in modal
      selectMode: 'single',
      selected: [],
      // search table in modal
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
      // loading
      isShowLoading: false,
      disabledButton: false,
    }
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
  },
  destroyed() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', this.isVerticalMenuCollapsed)
  },
  setup() {
    const CustomerStoreModuleName = 'customer'

    // Register module
    if (!store.hasModule(CustomerStoreModuleName)) store.registerModule(CustomerStoreModuleName, customerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CustomerStoreModuleName)) store.unregisterModule(CustomerStoreModuleName)
    })

    const customerColumns = [
      { key: 'name', label: 'Name', sortable: true },
      { key: 'surname', label: 'Surname', sortable: true },
      { key: 'nick_name', label: 'Nick Name', sortable: true },
      { key: 'email', label: 'Email', sortable: true },
      { key: 'gender', label: 'Gender', sortable: true },
      { key: 'phone', label: 'Phone No.', sortable: true },
    ]

    const chartColors = {
      primaryColorShade: '#836AF9',
      yellowColor: '#ffe800',
      successColorShade: '#28dac6',
      warningColorShade: '#ffe802',
      warningLightColor: '#FDAC34',
      infoColorShade: '#299AFF',
      greyColor: '#4F5D70',
      blueColor: '#2c9aff',
      blueLightColor: '#84D0FF',
      greyLightColor: '#EDF1F4',
      tooltipShadow: 'rgba(0, 0, 0, 0.25)',
      lineChartPrimary: '#666ee8',
      lineChartDanger: '#ff4961',
      labelColor: '#6e6b7b',
      grid_line_color: 'rgba(200, 200, 200, 0.2)',
    }

    const barChart = {
      data: {
        labels: ['Sun, 3/7/2022', 'Mon, 4/7/2022', 'Tue, 5/7/2022', 'Wed, 6/7/2022', 'Thu, 7/7/2022', 'Fri, 8/7/2022', 'Sat, 9/7/2022'],
        datasets: [
          {
            data: [15600, 2500, 19650, 18200, 3200, 5000, 8500],
            backgroundColor: chartColors.infoColorShade,
            borderColor: 'transparent',
          },
        ],
      },
      options: {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'bottom',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                fontColor: chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              ticks: {
                stepSize: 5000,
                min: 0,
                max: 25000,
                fontColor: chartColors.labelColor,
              },
            },
          ],
        },
      },
    }

    // const doughnutChart = {
    //   options: {
    //     responsive: true,
    //     maintainAspectRatio: false,
    //     responsiveAnimationDuration: 500,
    //     cutoutPercentage: 60,
    //     legend: { display: false },
    //     tooltips: {
    //       callbacks: {
    //         label(tooltipItem, data) {
    //           const label = data.datasets[0].labels[tooltipItem.index] || ''
    //           const value = data.datasets[0].data[tooltipItem.index]
    //           const output = ` ${label} : ${value} %`
    //           return output
    //         },
    //       },
    //       shadowOffsetX: 1,
    //       shadowOffsetY: 1,
    //       shadowBlur: 8,
    //       shadowColor: chartColors.tooltipShadow,
    //       backgroundColor: $themeColors.light,
    //       titleFontColor: $themeColors.dark,
    //       bodyFontColor: $themeColors.dark,
    //     },
    //   },
    //   data: {
    //     datasets: [
    //       {
    //         labels: ['Wine', 'Whisky', 'Cocktail'],
    //         data: [30, 10, 60],
    //         backgroundColor: [chartColors.successColorShade, chartColors.warningLightColor, $themeColors.primary],
    //         borderWidth: 0,
    //         pointStyle: 'rectRounded',
    //       },
    //     ],
    //   },
    // }

    // const doughnutData = [
    //   {
    //     label: 'Cocktail', percentage: 60, color: 'text-primary',
    //   },
    //   {
    //     label: 'Whisky', percentage: 10, color: 'text-warning',
    //   },
    //   {
    //     label: 'Wine', percentage: 30, color: 'text-success',
    //   },
    // ]

    const pieChart = {
      series: [9, 3, 5],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        labels: ['Wine', 'Whisky', 'Cocktail'],
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        stroke: {
          width: 4,
        },
        colors: [$themeColors.primary, $themeColors.warning, $themeColors.danger],
      },
    }

    const pieData = [
      {
        text: 'Wine', result: 9, color: 'text-primary',
      },
      {
        text: 'Whisky', result: 3, color: 'text-warning',
      },
      {
        text: 'Cocktail', result: 5, color: 'text-danger',
      },
    ]

    const genderOption = [
      { value: 'Male', label: 'Male' },
      { value: 'Female', label: 'Female' },
      { value: 'LGBT', label: 'LGBT' },
    ]

    const branchId = 1

    const apperanceOption = ref([])

    store.dispatch('customer/getApperance', { id: branchId })
      .then(response => {
        if (response !== null) {
          response.data.forEach(data => {
            apperanceOption.value.push({
              value: data.item, label: data.item,
            })
          })
        }
      })
      .catch(error => {

      })

    const favDrinkOption = ref([])

    store.dispatch('customer/getDrink', { id: branchId })
      .then(response => {
        if (response !== null) {
          response.data.forEach(data => {
            favDrinkOption.value.push({
              value: data.item, label: data.item,
            })
          })
        }
      })
      .catch(error => {

      })

    const favBrandOption = ref([])

    store.dispatch('customer/getBrand', { id: branchId })
      .then(response => {
        if (response !== null) {
          response.data.forEach(data => {
            favBrandOption.value.push({
              value: data.item, label: data.item,
            })
          })
        }
      })
      .catch(error => {

      })

    const allergyOption = ref([])

    store.dispatch('customer/getAllergy', { id: branchId })
      .then(response => {
        if (response !== null) {
          response.data.forEach(data => {
            allergyOption.value.push({
              value: data.item, label: data.item,
            })
          })
        }
      })
      .catch(error => {

      })

    return {
      customerColumns,
      chartColors,
      barChart,
      // doughnutChart,
      // doughnutData,
      pieChart,
      pieData,
      genderOption,
      apperanceOption,
      favDrinkOption,
      favBrandOption,
      allergyOption,
    }
  },
  methods: {
    showToast(variant, icon, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notification',
          icon,
          text,
          variant,
        },
      })
    },
    searchCustomer() {
      this.searchModel.gender = this.searchModel.gender !== '' ? this.searchModel.gender.value : ''

      store.dispatch('customer/searchCustomer', this.searchModel)
        .then(response => {
          // console.log('response.data', response.data)
          if (response !== null) {
            this.customers = response.data

            // Set the initial number of items
            this.totalRows = this.customers.length

            this.$refs['my-modal'].show()
          }
        })
        .catch(error => {

        })
    },
    saveCustomer() {
      this.disabledButton = true
      this.isShowLoading = true

      this.customerModel.apperance = ''
      if (this.customerModel.apperanceSelect !== '') {
        this.customerModel.apperanceSelect.forEach((apperance, index) => {
          this.customerModel.apperance += index < this.customerModel.apperanceSelect.length - 1 ? `${apperance.label}, ` : `${apperance.label}`
        })
      }

      this.customerModel.allergy = ''
      if (this.customerModel.allergySelect !== '') {
        this.customerModel.allergySelect.forEach((allergy, index) => {
          this.customerModel.allergy += index < this.customerModel.allergySelect.length - 1 ? `${allergy.label}, ` : `${allergy.label}`
        })
      }

      this.customerModel.favDrink = ''
      if (this.customerModel.favDrinkSelect !== '') {
        this.customerModel.favDrinkSelect.forEach((favDrink, index) => {
          this.customerModel.favDrink += index < this.customerModel.favDrinkSelect.length - 1 ? `${favDrink.label}, ` : `${favDrink.label}`
        })
      }

      this.customerModel.favBrand = ''
      if (this.customerModel.favBrandSelect !== '') {
        this.customerModel.favBrandSelect.forEach((favBrand, index) => {
          this.customerModel.favBrand += index < this.customerModel.favBrandSelect.length - 1 ? `${favBrand.label}, ` : `${favBrand.label}`
        })
      }

      this.customerModel.preferDrink = ''
      if (this.customerModel.preferDrinkSelect !== '') {
        this.customerModel.preferDrinkSelect.forEach((preferDrink, index) => {
          this.customerModel.preferDrink += index < this.customerModel.preferDrinkSelect.length - 1 ? `${preferDrink.label}, ` : `${preferDrink.label}`
        })
      }

      this.customerModel.preferBrand = ''
      if (this.customerModel.preferBrandSelect !== '') {
        this.customerModel.preferBrandSelect.forEach((preferBrand, index) => {
          this.customerModel.preferBrand += index < this.customerModel.preferBrandSelect.length - 1 ? `${preferBrand.label}, ` : `${preferBrand.label}`
        })
      }

      const saveModel = {
        id: this.customerModel.id,
        name: this.customerModel.name,
        surname: this.customerModel.surname,
        nick_name: this.customerModel.nickName,
        gender: this.customerModel.gender !== '' ? this.customerModel.gender.value : '',
        salution: this.customerModel.salution,
        email: this.customerModel.email,
        phone: this.customerModel.phone,
        birthday: this.customerModel.birthday,
        place_of_residence: this.customerModel.placeOfResidence,
        occupation: this.customerModel.occupation,
        apperance: this.customerModel.apperance,
        allergy: this.customerModel.allergy,
        concern: this.customerModel.concern,
        // fav_food: ,
        fav_drink: this.customerModel.favDrink,
        fav_brand: this.customerModel.favBrand,
        // fav_band: ,
        // prefer_food: ,
        prefer_drink: this.customerModel.preferDrink,
        prefer_brand: this.customerModel.preferBrand,
        // prefer_band: ,
        flag_phone: this.customerModel.isPhone ? '1' : '0',
        flag_sms: this.customerModel.isSMS ? '1' : '0',
        flag_email: this.customerModel.isEmail ? '1' : '0',
      }

      console.log('saveModel', saveModel)
      console.log('saveModel', JSON.stringify(saveModel))

      let type = ''

      if (this.customerModel.id === 0) {
        type = 'customer/addCustomer'
      } else {
        type = 'customer/editCustomer'
      }

      store.dispatch(type, saveModel.value)
        .then(response => {
          if (response.status === 200) {
            this.showToast('success', 'CheckIcon', 'Saved Successfully.')
            this.disabledButton = false
            this.isShowLoading = false
          }
        })
        .catch(error => {
          this.disabledButton = false
          this.isShowLoading = false
          this.showToast('danger', 'XCircleIcon', error.response.data)
        })
    },
    handleOk(bvModalEvt) {
      if (this.selected.length === 0) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()

        this.$swal({
          title: 'Warning!',
          text: ' Please select data !',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }

      this.clearCustomerData()

      store.dispatch('customer/getCustomer', { id: this.selected[0].id })
        .then(response => {
          console.log('response.data', response.data)
          if (response !== null) {
            this.customerModel.id = response.data.id
            this.customerModel.name = response.data.name
            this.customerModel.surname = response.data.surname
            this.customerModel.nickName = response.data.nick_name
            this.customerModel.gender = response.data.gender
            this.customerModel.salution = response.data.salution
            this.customerModel.email = response.data.email
            this.customerModel.phone = response.data.phone
            this.customerModel.birthday = response.data.birthday
            this.customerModel.placeOfResidence = response.data.place_of_residence
            this.customerModel.occupation = response.data.occupation

            if (response.data.apperance !== '') {
              const appearances = response.data.apperance.replaceAll(' ', '').split(',')
              appearances.forEach(item => {
                this.customerModel.apperanceSelect.push({
                  value: item, label: item,
                })
              })
            }

            if (response.data.fav_drink !== '') {
              const favDrinks = response.data.fav_drink.replaceAll(' ', '').split(',')
              favDrinks.forEach(item => {
                this.customerModel.favDrinkSelect.push({
                  value: item, label: item,
                })
              })
            }

            if (response.data.prefer_drink !== '') {
              const preferDrinks = response.data.prefer_drink.replaceAll(' ', '').split(',')
              preferDrinks.forEach(item => {
                this.customerModel.preferDrinkSelect.push({
                  value: item, label: item,
                })
              })
            }

            if (response.data.fav_brand !== '') {
              const favBrands = response.data.fav_brand.replaceAll(' ', '').split(',')
              favBrands.forEach(item => {
                this.customerModel.favBrandSelect.push({
                  value: item, label: item,
                })
              })
            }

            if (response.data.prefer_brand !== '') {
              const preferBrands = response.data.prefer_brand.replaceAll(' ', '').split(',')
              preferBrands.forEach(item => {
                this.customerModel.preferBrandSelect.push({
                  value: item, label: item,
                })
              })
            }

            if (response.data.allergy !== '') {
              const allergys = response.data.allergy.replaceAll(' ', '').split(',')
              allergys.forEach(item => {
                this.customerModel.allergySelect.push({
                  value: item, label: item,
                })
              })
            }

            this.customerModel.concern = response.data.concern

            this.customerModel.isPhone = response.data.flag_phone !== '0'
            this.customerModel.isSMS = response.data.flag_sms !== '0'
            this.customerModel.isEmail = response.data.flag_email !== '0'
          }
        })
        .catch(error => {

        })
    },
    onRowSelected(item) {
      this.selected = item
    },
    resetModal() {
      this.selected = []
      this.customers = []
      this.filter = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    clear() {
      this.clearSearchData()
      this.clearCustomerData()
    },
    clearSearchData() {
      // searchModel
      this.searchModel.name = ''
      this.searchModel.surname = ''
      this.searchModel.nickName = ''
      this.searchModel.email = ''
      this.searchModel.gender = ''
      this.searchModel.phone = ''
    },
    clearCustomerData() {
      // customerModel
      this.customerModel.id = 0
      this.customerModel.name = ''
      this.customerModel.surname = ''
      this.customerModel.nickName = ''
      this.customerModel.gender = ''
      this.customerModel.salution = ''
      this.customerModel.email = ''
      this.customerModel.phone = ''
      this.customerModel.birthday = ''
      this.customerModel.specialDay = ''
      this.customerModel.specialDate = ''
      this.customerModel.placeOfResidence = ''
      this.customerModel.occupation = ''
      this.customerModel.apperance = ''
      this.customerModel.apperanceSelect = []
      this.customerModel.favDrink = ''
      this.customerModel.favDrinkSelect = []
      this.customerModel.preferDrink = ''
      this.customerModel.preferDrinkSelect = []
      this.customerModel.favBrand = ''
      this.customerModel.favBrandSelect = []
      this.customerModel.preferBrand = ''
      this.customerModel.preferBrandSelect = []
      this.customerModel.allergy = ''
      this.customerModel.allergySelect = []
      this.customerModel.concern = ''
      this.customerModel.isPhone = false
      this.customerModel.isSMS = false
      this.customerModel.isEmail = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
